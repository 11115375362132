.achievement-list {
  display: flex;
  flex-flow: row;
  justify-content: space-between;

  margin-top: 24px;
  margin-bottom: 40px;

  @include landscapeTablet {
    margin-top: 64px;
    margin-bottom: 120px;
  }

  @include desktop {
    margin-top: 88px;
    margin-bottom: 160px;
  }

  @include wideDesktop {
    margin-top: 104px;
    margin-bottom: 228px;
  }
}

.achievement-item {
  width: calc(50% - 16px);

  &__heading {
    @extend .text-lg;
  }

  &__content {
    @extend .text-md;
    font-size: 8px;

    @include portraitTablet {
      font-size: 15px;
    }

    @include landscapeTablet {
      font-size: 22px;
    }
  }
}
