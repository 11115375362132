.social-list {
  display: flex;
  flex-flow: row;
  justify-content: space-between;
  align-items: center;

  margin-top: 32px;
}

.social-item {
  img {
    @media(max-width: $landscapeTabletWidth - 1) {
      width: 50%;
    }
  }
}
