.static-content {
	font-weight: 600;
	line-height: normal;

	font-size: 12px;

	@include portraitTablet {
		font-size: 22px;
	}

	@include landscapeTablet {
		font-size: 18px;
	}

	@include desktop {
		font-size: 26px;
	}

	@include wideDesktop {
		font-size: 36px;
	}

	&--880 {
		max-width: 880px;
	}

	&--1032 {
		max-width: 1032px;
	}

	p {
		margin: 0;
	}

	p:not(:first-child) {
		margin-top: 14px;

		@include landscapeTablet {
			margin-top: 18px;
		}

		@include desktop {
			margin-top: 26px;
		}

		@include wideDesktop {
			margin-top: 36px;
		}
	}
}

.headline {
	margin: 16px 0 106px;

	font-family: $secondaryFont;
	line-height: 1;

	@include desktop {
		font-size: 60px;
		margin: 16px 0 96px;
	}

	@include wideDesktop {
		font-size: 90px;
		margin: 16px 0 106px;
	}
}
