.gallery {
  position: relative;

  @media(max-width: $landscapeTabletWidth - 1) {
    &-desktop {
      display: none;
    }
  }

  @include landscapeTablet {
    margin: 0 75px;

    &-mobile {
      display: none;
    }
  }

  @include desktop {
    margin: 0 97px;
  }

  @include wideDesktop {
    margin: 0 130px;
  }
}

.gallery-wrapper {

  .swiper-slide {
    display: flex;
    flex-flow: column;
    align-items: center;
    justify-content: center;
  }
}

.gallery-row {
  display: flex;
  flex-flow: row !important;
  justify-content: space-between !important;
}

.gallery-item {
  height: 160px;
  width: auto;

  @include portraitTablet {
    height: 254px;
  }

  @include landscapeTablet {
    height: 254px;
  }

  @include desktop {
    height: 330px;
  }

  @include wideDesktop {
    height: 450px;
  }

  img {
    height: 100%;
  }
}

.gallery-item {
  border-radius: 35px;
  overflow: hidden;

  display: flex;
  flex-flow: column;
  align-items: center;
  justify-content: center;

  border: 2px solid $addlColor-2;

  @include wideDesktop {
    border: 3px solid $addlColor-2;
    border-radius: 70px;
  }
}

.gallery-pagination {
  display: flex;
  flex-flow: row;
  align-items: center;
  justify-content: center;

  margin-top: 16px;

  @include wideDesktop {
    margin-top: 32px;
  }

  .swiper-pagination-bullet {
    width: 10px;
    height: 10px;
    margin: 0 3px !important;
    opacity: 1;
    background-color: transparent;
    border: 2px solid $addlColor-2;

    @include landscapeTablet {
      width: 27px;
      height: 27px;
      margin: 0 13px !important;
    }

    @include desktop {
      width: 35px;
      height: 35px;
    }

    @include wideDesktop {
      width: 47px;
      height: 47px;
      margin: 0 33px !important;
    }
  }

  .swiper-pagination-bullet.swiper-pagination-bullet-active {
    border-color: $body-bg;
    background-color: $body-bg;
  }
}

.gallery-navigation {

  .gallery-button-next, .gallery-button-prev {
    position: absolute;
    cursor: pointer;
    z-index: 10;

    top: 80px;

    @include portraitTablet {
      top: 127px;
      transform: translateY(-50%);
    }

    @include desktop {
      top: 165px;
    }

    @include wideDesktop {
      top: 225px;
    }
  }

  .gallery-button-next {
    right: 24px;

    @include portraitTablet {
      right: 16px;
    }

    @include landscapeTablet {
      right: 36px;
    }
  }

  .gallery-button-prev {
    left: 24px;

    @include portraitTablet {
      left: 16px;
    }

    @include landscapeTablet {
      left: 36px;
    }
  }
}

.desktop-gallery-pagination, .desktop-gallery-navigation {
  @media(max-width: $landscapeTabletWidth - 1) {
    display: none;
  }
}

.mobile-gallery-pagination, .mobile-gallery-navigation {
  @include landscapeTablet {
    display: none;
  }
}

.gallery-txt {
  padding: 0 24px;
  margin: 40px auto 40px;

  @extend .text-md;

  @include portraitTablet {
    padding: 0 40px;
    margin: 48px auto 48px;
  }

  @include landscapeTablet {
    padding: 0 75px;
  }

  @include desktop {
    padding: 0 97px;
  }

  @include wideDesktop {
    padding: 0 130px;
    margin: 108px auto 96px;

    font-size: 50px;
  }
}


.swiper-slide {
 -webkit-backface-visibility: hidden;
 -webkit-transform: translate3d(0, 0, 0);
}

.swiper-wrapper {
 -webkit-transform-style: preserve-3d;
}
