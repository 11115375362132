@mixin mobileMax {
  @media only screen and (max-width: $mobileWidth) {
    @content;
  }
}

@mixin mobile {
  @media only screen and (min-width: $mobileWidth) {
    @content;
  }
}

@mixin portraitMax {
  @media only screen and (max-width: $portraitTabletWidth) {
    @content;
  }
}

@mixin portraitTablet {
  @media only screen and (min-width: $portraitTabletWidth) {
    @content;
  }
}

@mixin landscapeTablet {
  @media only screen and (min-width: $landscapeTabletWidth) {
    @content;
  }
}

@mixin landscapeMax {
  @media only screen and (max-width: $landscapeTabletWidth) {
    @content;
  }
}

@mixin desktop {
  @media only screen and (min-width: $desktopWidth) {
    @content;
  }
}

@mixin wideDesktop {
  @media only screen and (min-width: $wideDesktopWidth) {
    @content;
  }
}

@mixin narrowDesktop {
  @media only screen and (min-width: $desktopWidth) and (max-height: 800px) {
    @content;
  }
}
