.logo-list {
  display: flex;
  flex-flow: row;
  align-items: center;
  justify-content: space-between;
}

.logo-item {
  display: inline-block;

  img {
    max-width: 60%;
    max-height: 60%;

    @include landscapeTablet {
      max-width: 60%;
      max-height: 60%;
    }

    @include desktop {
      max-width: 80%;
      max-height: 80%;
    }

    @include wideDesktop {
      max-width: 100%;
      max-height: 100%;
    }
  }
}
