* {
  box-sizing: border-box;
}

html {
  position: relative;
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;

  scroll-behavior: smooth;
}


body {
  position: relative;
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  margin: 0;
  font-family: $primaryFont;

  color: $fontColor;
  background: $body-bg;

  flex-grow: 2;

  line-height: 1.6666667;
}


h2 {
  @extend .headline-2;
}

ul, ol {
  list-style: none;
  padding: 0;
  margin: 0;
}

a {
  color: inherit;
  text-decoration: none;

  //transition: all 200ms cubic-bezier(.19,.33,.3,1);

  &:hover {
    opacity: .5;
  }
}

[hidden] {
  display: none !important;
}
