.hamburger {
  position: fixed;
  top: 8px;
  right: 0;

  padding: 20px 16px;

  width: 56px;
  height: 56px;

  @include portraitTablet {
    width: 96px;
    height: 56px;
  }

  cursor: pointer;
  z-index: 1000;


  border-radius: 100%;

  span {
    position: absolute;
    display: block;
    width: calc(100% - 32px);
    height: 2px;
    margin: 2px 0;

    background-color: $body-bg;
    transition: all 300ms cubic-bezier(.19,.33,.3,1);

    &:nth-child(1) {
      top: 19px;

      @include portraitTablet {
      }
    }

    &:nth-child(2) {
      top: 25px;

      @include portraitTablet {
        top: 29px;
      }
    }

    &:nth-child(3) {
      top: 31px;

      @include portraitTablet {
        top: 39px;
      }
    }

    &:nth-child(4) {
      top: 25px;

      @include portraitTablet {
        top: 29px;
      }
    }

    &:nth-child(5) {
      top: 25px;

      @include portraitTablet {
        top: 29px;
      }
    }
  }

  @include portraitTablet {
    right: 16px;
    top: 32px;
  }

  @include landscapeTablet {
    display: none;
  }
}

.hamburger--js {
  transform: translateY(0);
  transition: all .5s ease;

  span {

    &:nth-child(1), &:nth-child(2), &:nth-child(3)   {
      transform: translate(-4px,0) scale(0,0);
    }

    &:nth-child(4) {
      transform:  rotate(45deg)
    }

    &:nth-child(5) {
      transform:  rotate(-45deg)
    }
  }
}
