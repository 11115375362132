@import "header/header";
@import "footer/footer";


@import "hero/hero";
@import "feature/feature";
@import "benefit/benefit";
@import "mean/mean";

@import "review/review";
@import "doodle/doodle";

@import "achievement/achievement";
@import "logo/logo";

@import "modal/modal";
@import "gallery/gallery";
