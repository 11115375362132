.feature-list {
  position: relative;
  font-size: 0;

  @include landscapeTablet {
    top: -40px;
  }

  @include desktop {
    top: -80px;
  }

  img {
    width: 100%;
  }
}

.feature-item {
  position: relative;
  z-index: 1;

  @extend .row;
  flex-flow: column;
  flex-direction: column-reverse;

  &:not(:last-child) {
    margin-bottom: 64px;
  }

  @include landscapeTablet {
    flex-flow: row;
  }

  &:nth-child(2) {

    @include landscapeTablet {
      margin: 32px 0;
      flex-direction: row-reverse;
    }

    @include desktop {
      margin: 96px 0 48px;
    }

    @include wideDesktop {
      margin: 96px 0 112px;
    }
  }
}

.feature-item__heading {
  display: flex;
  flex-flow: column;

  @extend .text-md;

  .feature-item:nth-child(1) & {
    justify-content: flex-end;
  }

  .feature-item:nth-child(2) & {
    justify-content: center;
  }

  .feature-item:nth-child(3) & {
    margin-top: 24px;
  }
}

.feature-item__img {
  margin-bottom: 16px;

  @include portraitTablet {
    margin-bottom: 32px;
  }

  @include landscapeTablet {
    margin-bottom: 0;
  }
}
