.hero {
  font-size: 0;

  display: flex;
  flex-flow: column;
  flex-direction: column-reverse;

  text-align: center;

  @include landscapeTablet {
    flex-flow: row;
    text-align: left;
  }

  &-image {
    margin-top: 32px;

    @include landscapeTablet {
      margin-top: -72px;
    }

    img {
      width: 100%;
    }
  }

  &-content {

    &__txt {

      font-family:  $secondaryFont;
      line-height: 1;

      text-transform: uppercase;

      font-size: 22px;

      @include portraitTablet {
        font-size: 50px;
      }

      @include landscapeTablet {
        font-size: 44px;
        margin-top: -64px;
      }

      @include desktop {
        font-size: 62px;
        margin-top: -8px;
      }

      @include wideDesktop {
        font-size: 84px;
      }
    }
  }
}
