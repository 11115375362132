.footer {
  display: flex;
  flex-flow: row;
  justify-content: space-between;
  align-items: center;

  margin-bottom: 40px;

  @include landscapeTablet {
    margin-bottom: 128px;
  }
}

.footer-logo {
  width: calc(50% - 80px);

  @media(max-width: $landscapeTabletWidth - 1) {
    display: none;
  }
}

.footer-menu {
  width: calc(50% - 80px);

  @media(max-width: $landscapeTabletWidth - 1) {
    width: 100%;

    .nav-list {
      display: none;
    }
  }
}
