.modal-dialog {

  @include landscapeTablet {
    width: 952px;
    max-width: 952px;
  }

  @include desktop {
    width: 1235px;
    max-width: 1235px;
  }

  @include wideDesktop {
    width: 1680px;
    max-width: 1680px;
  }
}

.modal-content {
  border-radius: 20px;

  border: 2px solid $body-bg;
  background-color: $addlColor-3;
  color: $body-bg;

  @include landscapeTablet {
    border-radius: 35px;
    border: 2px solid $body-bg;
  }

  @include desktop {
  }

  @include wideDesktop {
    border: 3px solid $body-bg;
    border-radius: 72px;
  }
}

.modal-header {
  border: none;
}

.modal-body {
  padding: 0;
  margin-top: 40px;

  @include portraitTablet {
    margin-top: 74px;
  }

  @include  desktop{
    margin-top: 106px;
  }

  @include wideDesktop {
    margin-top: 144px;
  }
}

.btn-close {
  position: absolute;
  top: 18px;
  right: 18px;

  background-color: transparent;
  border: none;

  @include portraitTablet {

  }

  @include landscapeTablet {
    top: 20px;
    right: 20px;
  }

  @include desktop {
    top: 27px;
    right: 27px;
  }

  @include wideDesktop {
    top: 37px;
    right: 37px;
  }
}
