.section-heading--center {
  text-align: center;

  h2 {
    margin-top: 40px;

    @include landscapeTablet {
      margin-top: 40px;
    }

    @include desktop {
      margin-top: 72px;
    }
  }
}
