
.icon--arrow {
  width: 36px;
  height: 40px;

  //margin: 32px 0 56px;

  @include landscapeTablet {
    width: 80px;
    height: 60px;
    margin: 40px 0 48px;
  }

  @include desktop {
    margin: 48px 0 72px;
  }

  @include wideDesktop {
    margin: 128px 0 72px;
  }
}

.icon--prev, .icon--next {
  width: 12px;
  height: 20px;

  @include portraitTablet {
    width: 16px;
    height: 32px;
  }

  @include landscapeTablet {
    width: 23px;
    height: 45px;
  }

  @include desktop {
    width: 30px;
    height: 60px;
  }

  @include wideDesktop {
    width: 40px;
    height: 80px;
  }
}

.icon--close {
  width: 18px;
  height: 18px;

  @include portraitTablet {
    width: 26px;
    height: 26px;
  }

  @include landscapeTablet {
    width: 28px;
    height: 28px;
  }

  @include desktop {
    width: 35px;
    height: 35px;
  }

  @include wideDesktop {
    width: 48px;
    height: 48px;
  };
}
