.nav-list {
  display: flex;
  flex-flow: row;
  justify-content: space-between;

  font-weight: 600;
  line-height: 1;

  text-transform: uppercase;

  @include desktop {
    font-size: 21px;
  }

  @include wideDesktop {
    font-size: 28px;
  }
}
