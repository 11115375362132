

.container {
  margin-right: auto;
  margin-left: auto;
  padding-right: 16px;
  padding-left: 16px;
  width: 100%;
}

.container-fluid {
  width: 100%;
  margin-right: auto;
  margin-left: auto;
  padding-right: 16px;
  padding-left: 16px;
}

@media (min-width: 320px) {
  .container {
    width: 352px;
  }
}

@media (min-width: 768px) {
  .container {
    width: 672px;
  }
}

@media (min-width: 980px) {
  .container {
    width: 992px;
  }
}

@media (min-width: 1400px) {
  .container {
    width: 1382px;
  }
}

@media (min-width: 1880px) {
  .container {
    width: 1824px;
  }
}

.row {
  display: flex;
  flex-wrap: wrap;
  margin-right: -16px;
  margin-left: -16px;
}

.no-gutters {
  margin-right: 0;
  margin-left: 0;
}

.no-gutters > .col,
.no-gutters > [class*="col-"] {
  padding-right: 0;
  padding-left: 0;
}

.col-xs-1, .col-xs-2, .col-xs-3, .col-xs-4, .col-xs-5, .col-xs-6, .col-xs-7, .col-xs-8, .col-xs-9, .col-xs-10, .col-xs-11, .col-xs-12, .col-xs,
.col-xs-auto, .col-sm-1, .col-sm-2, .col-sm-3, .col-sm-4, .col-sm-5, .col-sm-6, .col-sm-7, .col-sm-8, .col-sm-9, .col-sm-10, .col-sm-11, .col-sm-12, .col-sm,
.col-sm-auto, .col-md-1, .col-md-2, .col-md-3, .col-md-4, .col-md-5, .col-md-6, .col-md-7, .col-md-8, .col-md-9, .col-md-10, .col-md-11, .col-md-12, .col-md,
.col-md-auto, .col-lg-1, .col-lg-2, .col-lg-3, .col-lg-4, .col-lg-5, .col-lg-6, .col-lg-7, .col-lg-8, .col-lg-9, .col-lg-10, .col-lg-11, .col-lg-12, .col-lg,
.col-lg-auto, .col-xl-1, .col-xl-2, .col-xl-3, .col-xl-4, .col-xl-5, .col-xl-6, .col-xl-7, .col-xl-8, .col-xl-9, .col-xl-10, .col-xl-11, .col-xl-12, .col-xl,
.col-xl-auto {
  position: relative;
  width: 100%;
  min-height: 1px;
  padding-right: 16px;
  padding-left: 16px;
}


.col-xs {
  flex-basis: 0;
  flex-grow: 1;
  max-width: 100%;
}

.col-xs-auto {
  flex: 0 0 auto;
  width: auto;
  max-width: none;
}

.col-xs-1 {
  flex: 0 0 8.333333%;
  max-width: 8.333333%;
}

.col-xs-2 {
  flex: 0 0 16.666667%;
  max-width: 16.666667%;
}

.col-xs-3 {
  flex: 0 0 25%;
  max-width: 25%;
}

.col-xs-4 {
  flex: 0 0 33.333333%;
  max-width: 33.333333%;
}

.col-xs-5 {
  flex: 0 0 41.666667%;
  max-width: 41.666667%;
}

.col-xs-6 {
  flex: 0 0 50%;
  max-width: 50%;
}

.col-xs-7 {
  flex: 0 0 58.333333%;
  max-width: 58.333333%;
}

.col-xs-8 {
  flex: 0 0 66.666667%;
  max-width: 66.666667%;
}

.col-xs-9 {
  flex: 0 0 75%;
  max-width: 75%;
}

.col-xs-10 {
  flex: 0 0 83.333333%;
  max-width: 83.333333%;
}

.col-xs-11 {
  flex: 0 0 91.666667%;
  max-width: 91.666667%;
}

.col-xs-12 {
  flex: 0 0 100%;
  max-width: 100%;
}

.pull-xs-0 {
  right: auto;
}

.pull-xs-1 {
  right: 8.333333%;
}

.pull-xs-2 {
  right: 16.666667%;
}

.pull-xs-3 {
  right: 25%;
}

.pull-xs-4 {
  right: 33.333333%;
}

.pull-xs-5 {
  right: 41.666667%;
}

.pull-xs-6 {
  right: 50%;
}

.pull-xs-7 {
  right: 58.333333%;
}

.pull-xs-8 {
  right: 66.666667%;
}

.pull-xs-9 {
  right: 75%;
}

.pull-xs-10 {
  right: 83.333333%;
}

.pull-xs-11 {
  right: 91.666667%;
}

.pull-xs-12 {
  right: 100%;
}

.push-xs-0 {
  left: auto;
}

.push-xs-1 {
  left: 8.333333%;
}

.push-xs-2 {
  left: 16.666667%;
}

.push-xs-3 {
  left: 25%;
}

.push-xs-4 {
  left: 33.333333%;
}

.push-xs-5 {
  left: 41.666667%;
}

.push-xs-6 {
  left: 50%;
}

.push-xs-7 {
  left: 58.333333%;
}

.push-xs-8 {
  left: 66.666667%;
}

.push-xs-9 {
  left: 75%;
}

.push-xs-10 {
  left: 83.333333%;
}

.push-xs-11 {
  left: 91.666667%;
}

.push-xs-12 {
  left: 100%;
}

.offset-xs-1 {
  margin-left: 8.333333%;
}

.offset-xs-2 {
  margin-left: 16.666667%;
}

.offset-xs-3 {
  margin-left: 25%;
}

.offset-xs-4 {
  margin-left: 33.333333%;
}

.offset-xs-5 {
  margin-left: 41.666667%;
}

.offset-xs-6 {
  margin-left: 50%;
}

.offset-xs-7 {
  margin-left: 58.333333%;
}

.offset-xs-8 {
  margin-left: 66.666667%;
}

.offset-xs-9 {
  margin-left: 75%;
}

.offset-xs-10 {
  margin-left: 83.333333%;
}

.offset-xs-11 {
  margin-left: 91.666667%;
}

@media (min-width: 768px) {

  .col-sm {
    flex-basis: 0;
    flex-grow: 1;
    max-width: 100%;
  }

  .col-sm-auto {
    flex: 0 0 auto;
    width: auto;
    max-width: none;
  }

  .col-sm-1 {
    flex: 0 0 8.333333%;
    max-width: 8.333333%;
  }

  .col-sm-2 {
    flex: 0 0 16.666667%;
    max-width: 16.666667%;
  }

  .col-sm-3 {
    flex: 0 0 25%;
    max-width: 25%;
  }

  .col-sm-4 {
    flex: 0 0 33.333333%;
    max-width: 33.333333%;
  }

  .col-sm-5 {
    flex: 0 0 41.666667%;
    max-width: 41.666667%;
  }

  .col-sm-6 {
    flex: 0 0 50%;
    max-width: 50%;
  }

  .col-sm-7 {
    flex: 0 0 58.333333%;
    max-width: 58.333333%;
  }

  .col-sm-8 {
    flex: 0 0 66.666667%;
    max-width: 66.666667%;
  }

  .col-sm-9 {
    flex: 0 0 75%;
    max-width: 75%;
  }

  .col-sm-10 {
    flex: 0 0 83.333333%;
    max-width: 83.333333%;
  }

  .col-sm-11 {
    flex: 0 0 91.666667%;
    max-width: 91.666667%;
  }

  .col-sm-12 {
    flex: 0 0 100%;
    max-width: 100%;
  }

  .pull-sm-0 {
    right: auto;
  }

  .pull-sm-1 {
    right: 8.333333%;
  }

  .pull-sm-2 {
    right: 16.666667%;
  }

  .pull-sm-3 {
    right: 25%;
  }

  .pull-sm-4 {
    right: 33.333333%;
  }

  .pull-sm-5 {
    right: 41.666667%;
  }

  .pull-sm-6 {
    right: 50%;
  }

  .pull-sm-7 {
    right: 58.333333%;
  }

  .pull-sm-8 {
    right: 66.666667%;
  }

  .pull-sm-9 {
    right: 75%;
  }

  .pull-sm-10 {
    right: 83.333333%;
  }

  .pull-sm-11 {
    right: 91.666667%;
  }

  .pull-sm-12 {
    right: 100%;
  }

  .push-sm-0 {
    left: auto;
  }

  .push-sm-1 {
    left: 8.333333%;
  }

  .push-sm-2 {
    left: 16.666667%;
  }

  .push-sm-3 {
    left: 25%;
  }

  .push-sm-4 {
    left: 33.333333%;
  }

  .push-sm-5 {
    left: 41.666667%;
  }

  .push-sm-6 {
    left: 50%;
  }

  .push-sm-7 {
    left: 58.333333%;
  }

  .push-sm-8 {
    left: 66.666667%;
  }

  .push-sm-9 {
    left: 75%;
  }

  .push-sm-10 {
    left: 83.333333%;
  }

  .push-sm-11 {
    left: 91.666667%;
  }

  .push-sm-12 {
    left: 100%;
  }

  .offset-sm-0 {
    margin-left: 0%;
  }

  .offset-sm-1 {
    margin-left: 8.333333%;
  }

  .offset-sm-2 {
    margin-left: 16.666667%;
  }

  .offset-sm-3 {
    margin-left: 25%;
  }

  .offset-sm-4 {
    margin-left: 33.333333%;
  }

  .offset-sm-5 {
    margin-left: 41.666667%;
  }

  .offset-sm-6 {
    margin-left: 50%;
  }

  .offset-sm-7 {
    margin-left: 58.333333%;
  }

  .offset-sm-8 {
    margin-left: 66.666667%;
  }

  .offset-sm-9 {
    margin-left: 75%;
  }

  .offset-sm-10 {
    margin-left: 83.333333%;
  }

  .offset-sm-11 {
    margin-left: 91.666667%;
  }
}

@media (min-width: 1024px) {
  .col-md {
    flex-basis: 0;
    flex-grow: 1;
    max-width: 100%;
  }

  .col-md-auto {
    flex: 0 0 auto;
    width: auto;
    max-width: none;
  }

  .col-md-1 {
    flex: 0 0 8.333333%;
    max-width: 8.333333%;
  }

  .col-md-2 {
    flex: 0 0 16.666667%;
    max-width: 16.666667%;
  }

  .col-md-3 {
    flex: 0 0 25%;
    max-width: 25%;
  }

  .col-md-4 {
    flex: 0 0 33.333333%;
    max-width: 33.333333%;
  }

  .col-md-5 {
    flex: 0 0 41.666667%;
    max-width: 41.666667%;
  }

  .col-md-6 {
    flex: 0 0 50%;
    max-width: 50%;
  }

  .col-md-7 {
    flex: 0 0 58.333333%;
    max-width: 58.333333%;
  }

  .col-md-8 {
    flex: 0 0 66.666667%;
    max-width: 66.666667%;
  }

  .col-md-9 {
    flex: 0 0 75%;
    max-width: 75%;
  }

  .col-md-10 {
    flex: 0 0 83.333333%;
    max-width: 83.333333%;
  }

  .col-md-11 {
    flex: 0 0 91.666667%;
    max-width: 91.666667%;
  }

  .col-md-12 {
    flex: 0 0 100%;
    max-width: 100%;
  }

  .pull-md-0 {
    right: auto;
  }

  .pull-md-1 {
    right: 8.333333%;
  }

  .pull-md-2 {
    right: 16.666667%;
  }

  .pull-md-3 {
    right: 25%;
  }

  .pull-md-4 {
    right: 33.333333%;
  }

  .pull-md-5 {
    right: 41.666667%;
  }

  .pull-md-6 {
    right: 50%;
  }

  .pull-md-7 {
    right: 58.333333%;
  }

  .pull-md-8 {
    right: 66.666667%;
  }

  .pull-md-9 {
    right: 75%;
  }

  .pull-md-10 {
    right: 83.333333%;
  }

  .pull-md-11 {
    right: 91.666667%;
  }

  .pull-md-12 {
    right: 100%;
  }

  .push-md-0 {
    left: auto;
  }

  .push-md-1 {
    left: 8.333333%;
  }

  .push-md-2 {
    left: 16.666667%;
  }

  .push-md-3 {
    left: 25%;
  }

  .push-md-4 {
    left: 33.333333%;
  }

  .push-md-5 {
    left: 41.666667%;
  }

  .push-md-6 {
    left: 50%;
  }

  .push-md-7 {
    left: 58.333333%;
  }

  .push-md-8 {
    left: 66.666667%;
  }

  .push-md-9 {
    left: 75%;
  }

  .push-md-10 {
    left: 83.333333%;
  }

  .push-md-11 {
    left: 91.666667%;
  }

  .push-md-12 {
    left: 100%;
  }

  .offset-md-0 {
    margin-left: 0%;
  }

  .offset-md-1 {
    margin-left: 8.333333%;
  }

  .offset-md-2 {
    margin-left: 16.666667%;
  }

  .offset-md-3 {
    margin-left: 25%;
  }

  .offset-md-4 {
    margin-left: 33.333333%;
  }

  .offset-md-5 {
    margin-left: 41.666667%;
  }

  .offset-md-6 {
    margin-left: 50%;
  }

  .offset-md-7 {
    margin-left: 58.333333%;
  }

  .offset-md-8 {
    margin-left: 66.666667%;
  }

  .offset-md-9 {
    margin-left: 75%;
  }

  .offset-md-10 {
    margin-left: 83.333333%;
  }

  .offset-md-11 {
    margin-left: 91.666667%;
  }
}

@media (min-width: 1440px) {
  .col-lg {
    flex-basis: 0;
    flex-grow: 1;
    max-width: 100%;
  }

  .col-lg-auto {
    flex: 0 0 auto;
    width: auto;
    max-width: none;
  }

  .col-lg-1 {
    flex: 0 0 8.333333%;
    max-width: 8.333333%;
  }

  .col-lg-2 {
    flex: 0 0 16.666667%;
    max-width: 16.666667%;
  }

  .col-lg-3 {
    flex: 0 0 25%;
    max-width: 25%;
  }

  .col-lg-4 {
    flex: 0 0 33.333333%;
    max-width: 33.333333%;
  }

  .col-lg-5 {
    flex: 0 0 41.666667%;
    max-width: 41.666667%;
  }

  .col-lg-6 {
    flex: 0 0 50%;
    max-width: 50%;
  }

  .col-lg-7 {
    flex: 0 0 58.333333%;
    max-width: 58.333333%;
  }

  .col-lg-8 {
    flex: 0 0 66.666667%;
    max-width: 66.666667%;
  }

  .col-lg-9 {
    flex: 0 0 75%;
    max-width: 75%;
  }

  .col-lg-10 {
    flex: 0 0 83.333333%;
    max-width: 83.333333%;
  }

  .col-lg-11 {
    flex: 0 0 91.666667%;
    max-width: 91.666667%;
  }

  .col-lg-12 {
    flex: 0 0 100%;
    max-width: 100%;
  }

  .pull-lg-0 {
    right: auto;
  }
  .pull-lg-1 {
    right: 8.333333%;
  }
  .pull-lg-2 {
    right: 16.666667%;
  }
  .pull-lg-3 {
    right: 25%;
  }
  .pull-lg-4 {
    right: 33.333333%;
  }
  .pull-lg-5 {
    right: 41.666667%;
  }
  .pull-lg-6 {
    right: 50%;
  }
  .pull-lg-7 {
    right: 58.333333%;
  }
  .pull-lg-8 {
    right: 66.666667%;
  }
  .pull-lg-9 {
    right: 75%;
  }
  .pull-lg-10 {
    right: 83.333333%;
  }
  .pull-lg-11 {
    right: 91.666667%;
  }
  .pull-lg-12 {
    right: 100%;
  }
  .push-lg-0 {
    left: auto;
  }
  .push-lg-1 {
    left: 8.333333%;
  }
  .push-lg-2 {
    left: 16.666667%;
  }
  .push-lg-3 {
    left: 25%;
  }
  .push-lg-4 {
    left: 33.333333%;
  }
  .push-lg-5 {
    left: 41.666667%;
  }
  .push-lg-6 {
    left: 50%;
  }
  .push-lg-7 {
    left: 58.333333%;
  }
  .push-lg-8 {
    left: 66.666667%;
  }
  .push-lg-9 {
    left: 75%;
  }
  .push-lg-10 {
    left: 83.333333%;
  }
  .push-lg-11 {
    left: 91.666667%;
  }
  .push-lg-12 {
    left: 100%;
  }
  .offset-lg-0 {
    margin-left: 0%;
  }
  .offset-lg-1 {
    margin-left: 8.333333%;
  }
  .offset-lg-2 {
    margin-left: 16.666667%;
  }
  .offset-lg-3 {
    margin-left: 25%;
  }
  .offset-lg-4 {
    margin-left: 33.333333%;
  }
  .offset-lg-5 {
    margin-left: 41.666667%;
  }
  .offset-lg-6 {
    margin-left: 50%;
  }
  .offset-lg-7 {
    margin-left: 58.333333%;
  }
  .offset-lg-8 {
    margin-left: 66.666667%;
  }
  .offset-lg-9 {
    margin-left: 75%;
  }
  .offset-lg-10 {
    margin-left: 83.333333%;
  }
  .offset-lg-11 {
    margin-left: 91.666667%;
  }
}

@media (min-width: 1920px) {
  .col-xl {
    flex-basis: 0;
    flex-grow: 1;
    max-width: 100%;
  }

  .col-xl-auto {
    flex: 0 0 auto;
    width: auto;
    max-width: none;
  }

  .col-xl-1 {
    flex: 0 0 8.333333%;
    max-width: 8.333333%;
  }

  .col-xl-2 {
    flex: 0 0 16.666667%;
    max-width: 16.666667%;
  }

  .col-xl-3 {
    flex: 0 0 25%;
    max-width: 25%;
  }

  .col-xl-4 {
    flex: 0 0 33.333333%;
    max-width: 33.333333%;
  }

  .col-xl-5 {
    flex: 0 0 41.666667%;
    max-width: 41.666667%;
  }

  .col-xl-6 {
    flex: 0 0 50%;
    max-width: 50%;
  }

  .col-xl-7 {
    flex: 0 0 58.333333%;
    max-width: 58.333333%;
  }

  .col-xl-8 {
    flex: 0 0 66.666667%;
    max-width: 66.666667%;
  }

  .col-xl-9 {
    flex: 0 0 75%;
    max-width: 75%;
  }

  .col-xl-10 {
    flex: 0 0 83.333333%;
    max-width: 83.333333%;
  }

  .col-xl-11 {
    flex: 0 0 91.666667%;
    max-width: 91.666667%;
  }

  .col-xl-12 {
    flex: 0 0 100%;
    max-width: 100%;
  }

  .pull-xl-0 {
    right: auto;
  }

  .pull-xl-1 {
    right: 8.333333%;
  }

  .pull-xl-2 {
    right: 16.666667%;
  }

  .pull-xl-3 {
    right: 25%;
  }

  .pull-xl-4 {
    right: 33.333333%;
  }

  .pull-xl-5 {
    right: 41.666667%;
  }

  .pull-xl-6 {
    right: 50%;
  }

  .pull-xl-7 {
    right: 58.333333%;
  }

  .pull-xl-8 {
    right: 66.666667%;
  }

  .pull-xl-9 {
    right: 75%;
  }

  .pull-xl-10 {
    right: 83.333333%;
  }

  .pull-xl-11 {
    right: 91.666667%;
  }

  .pull-xl-12 {
    right: 100%;
  }

  .push-xl-0 {
    left: auto;
  }

  .push-xl-1 {
    left: 8.333333%;
  }

  .push-xl-2 {
    left: 16.666667%;
  }

  .push-xl-3 {
    left: 25%;
  }

  .push-xl-4 {
    left: 33.333333%;
  }

  .push-xl-5 {
    left: 41.666667%;
  }

  .push-xl-6 {
    left: 50%;
  }

  .push-xl-7 {
    left: 58.333333%;
  }

  .push-xl-8 {
    left: 66.666667%;
  }

  .push-xl-9 {
    left: 75%;
  }

  .push-xl-10 {
    left: 83.333333%;
  }

  .push-xl-11 {
    left: 91.666667%;
  }

  .push-xl-12 {
    left: 100%;
  }

  .offset-xl-0 {
    margin-left: 0%;
  }

  .offset-xl-1 {
    margin-left: 8.333333%;
  }

  .offset-xl-2 {
    margin-left: 16.666667%;
  }

  .offset-xl-3 {
    margin-left: 25%;
  }

  .offset-xl-4 {
    margin-left: 33.333333%;
  }

  .offset-xl-5 {
    margin-left: 41.666667%;
  }

  .offset-xl-6 {
    margin-left: 50%;
  }

  .offset-xl-7 {
    margin-left: 58.333333%;
  }

  .offset-xl-8 {
    margin-left: 66.666667%;
  }

  .offset-xl-9 {
    margin-left: 75%;
  }

  .offset-xl-10 {
    margin-left: 83.333333%;
  }

  .offset-xl-11 {
    margin-left: 91.666667%;
  }
}

.order-first {
  order: -1;
}

.order-last {
  order: 1;
}

.order-0 {
  order: 0;
}
