.item--animated {
  opacity: 0;
  transform: translateY(10%);
  transition: all 500ms cubic-bezier(.19,.33,.3,1);
}

.item--animated.isInView{
  opacity: 1;
  transform: translateY(0%);
}
