

.headline {

  &--uppercase {
    text-transform: uppercase;
    letter-spacing: 1.5px;
  }

  &--inverse {
    position: relative;
    display: flex;
    flex-flow: column;
    justify-content: center;

    color: $body-bg;
    background-color: $addlColor-2;

    text-transform: uppercase;
    letter-spacing: 1.5px;

    padding: 8px 0;

		@include portraitTablet {
			margin-bottom: 48px;
			padding: 12px 0;
		}

    @include desktop {
      margin-bottom: 72px;
      padding: 16px 0;
    }

    @include wideDesktop {
      margin-bottom: 96px;
      padding: 24px 0;
    }

    &:after {
      content: '';
      position: absolute;
      top: 0;
      right: 100%;
      bottom: 0;
      left: calc((100% - 1824px) / 2);
      height: 100%;
      background-color: $addlColor-2;
    }
  }
}

.headline-2 {
	font-family: $secondaryFont;
	font-size: 90px;
	line-height: 1;

	margin: 32px 0;

  font-size: 22px;

	@include portraitTablet {
    font-size: 48px;
	}

	@include landscapeTablet {
		font-size: 44px;
	}

	@include desktop {
		font-size: 62px;
	}

	@include wideDesktop {
		font-size: 90px;
	}
}


.text-lg {
  font-family: $secondaryFont;
  line-height: 1;

  font-size: 24px;

  @include portraitTablet {
    font-size: 48px;
  }

  @include landscapeTablet {
    font-size: 44px;
  }

  @include desktop {
    font-size: 62px;
  }

  @include wideDesktop {
    font-size: 90px;
  }
}

.text-md {
  font-size: 12px;
  font-weight: 600;
  line-height: normal;

  @include portraitTablet {
    font-size: 22px;
  }

  @include landscapeTablet {
    font-size: 18px;
  }

  @include desktop {
    font-size: 26px;
  }

  @include wideDesktop {
    font-size: 36px;
  }
}

.text-sm {
  font-weight: 600;
  line-height: normal;
  font-size: 8px;

  @include portraitTablet {
    font-size: 15px;
  }

  @include landscapeTablet {
    font-size: 15px;
  }

  @include desktop {
    font-size: 22px;
  }

  @include wideDesktop {
    font-size: 28px;
  }
}

.rounded {
  border-radius: 20px;

  @include portraitTablet {
    border-radius: 30px;
  }

  @include landscapeTablet {
    border-radius: 35px;
  }

  @include desktop {
    border-radius: 50px;
  }

  @include wideDesktop {
    border-radius: 70px;
  }
}

.caption {
	margin: 4vw 0 4vw;

  @include landscapeTablet {
    margin: 1vw 0 2.5vw;
  }
}
