.contact {
  display: flex;
  flex-flow: row;
  justify-content: space-between;
  align-items: center;

  margin-bottom: 128px;
}

.contact-logo {
  width: calc(50% - 80px);
}

.contact-menu{
  width: calc(50% - 80px);
}
