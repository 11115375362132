@import "helpers/variables";
@import "helpers/mixins";
@import "helpers/functions";
@import "helpers/fluid-typography";

@import "vendor/libs";

@import "base/basic";
@import "base/general";

@import "../../node_modules/bootstrap/scss/functions";
@import "../../node_modules/bootstrap/scss/variables";
@import "../../node_modules/bootstrap/scss/mixins";
@import "../../node_modules/bootstrap/scss/utilities";
@import "../../node_modules/bootstrap/scss/modal";
@import "../../node_modules/bootstrap/scss/transitions";

@import "../../node_modules/swiper/swiper-bundle";

@import "../blocks/components/components";
@import "../blocks/modules/modules";
