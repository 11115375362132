.page-header {
  position: absolute;
  top: 16px;
  right: 0;
  left: 0;

  color: $body-bg;

  @include portraitTablet {
    top: 32px;
  }


}

.header {
  position: relative;
  display: flex;
  flex-flow: row;
  justify-content: space-between;
  align-items: center;

  margin-bottom: 128px;
}

.header-logo {
  transition: all 200ms cubic-bezier(.19,.33,.3,1);

  @include mobileMax {
    width: calc(100% - 40px);
  }

  @include landscapeTablet {
    display: inline-block;
    width: 50%;
    position: static;
  }

  &--js {
    opacity: 0;
    visibility: hidden;
  }
}

.header-menu {
  position: absolute;
  top: 0;
  right: 0;
  left: 0;
  z-index: 10;

  padding: 14px 16px;

  font-size: 12px;

  opacity: 0;
  visibility: hidden;

  transition: all 500ms cubic-bezier(.19,.33,.3,1);

  @include mobileMax {
    width: calc(100% - 40px);
  }

  @include portraitTablet {
    padding: 24px 16px;
  }

  @include landscapeTablet {
    width: calc(50% - 80px);
    position: static;

    transition: none;

    opacity: 1;
    visibility: visible;
  }
}

.header-menu--js {
  @media(max-width: $landscapeTabletWidth - 1) {
    opacity: 1;
    visibility: visible;
  }
}
