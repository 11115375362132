.benefit-list {
  margin-top: 56px;
}

.benefit-item {
  align-items: center;
  @extend .row;

  &:not(:last-child) {
    margin-bottom: 64px;
  }
  @include landscapeTablet {
    &:not(:last-child) {
      margin-bottom: 0;
    }

    &:nth-child(2n) {
      flex-direction: row-reverse;
    }
  }

  &:nth-child(1) {}

  &:nth-child(2) {
    margin-top: 48px;
  }

  &:nth-child(3) {}

  &:nth-child(4) {
    @include desktop {
      margin-top: 64px;
    }
  }

  &:nth-child(5) {
    margin-top: 88px;
    @include desktop {
      margin-top: 144px;
    }
  }
}

.benefit-item__heading {
  display: flex;
  flex-flow: column;
  align-items: center;
  justify-content: center;

  &-img {
    width: 200px;
    height: 200px;
    border: 2px solid $addlColor-2;
    border-radius: 100%;
    z-index: 1;
    @include portraitTablet {
      width: 368px;
      height: 368px;
    }
    @include landscapeTablet {
      width: 296px;
      height: 296px;
    }
    @include desktop {
      width: 428px;
      height: 428px;
      border: 2px solid $addlColor-2;
    }
    @include wideDesktop {
      width: 576px;
      height: 576px;
      border: 3px solid $addlColor-2;
    }
  }

  img {
    width: 100%;
    object-fit: cover;
    border-radius: 100%;
    overflow: hidden;
  }
}

.benefit-item__content {
  display: flex;
  flex-flow: row;
  margin-top: 32px;

  &-number {
    display: flex;
    flex-flow: column;
    align-items: center;
    font-size: 32px;
    font-family: $secondaryFont;
    line-height: 1;
    background-color: $addlColor-2;
    color: $body-bg;
    padding: 8px;
    @include portraitTablet {
      font-size: 64px;
    }
    @include landscapeTablet {
      font-size: 80px;
    }
    @include desktop {
      width: 140px;
      min-width: 140px;
      font-size: 96px;
    }
    @include wideDesktop {
      width: 192px;
      min-width: 192px;
      font-size: 128px;
    }
  }

  &-txt {
    padding-left: 8px;
    @extend .text-md;
    @include landscapeTablet {
      padding-left: 32px;
    }
  }
}