.doodle-list {
  display: flex;
  flex-flow: row;
  justify-content: space-between;

  margin-top: 24px;

  @include landscapeTablet {
    margin-top: 64px;
  }

  @include desktop {
    margin-top: 96px;
  }

  @include wideDesktop {
    margin-top: 128px;
  }
}

.doodle-item {
  position: relative;
  width: 96px;
  height: 96px;
  border-radius: 100%;

  display: flex;
  flex-flow: column;
  align-items: center;
  justify-content: center;

  border: 2px solid $body-bg;

  @include portraitTablet {
    width: 178px;
    height: 178px;
  }

  @include landscapeTablet {
    width: 240px;
    height: 240px;
  }

  @include desktop {
    width: 336px;
    height: 336px;
  }

  @include wideDesktop {
    width: 448px;
    height: 448px;
  }

  &:after {
    content: '';
    position: absolute;
    top: 4px;
    right: 4px;
    bottom: 4px;
    left: 4px;
    border-radius: 100%;
    border: 2px dashed $body-bg;

    @include desktop {
      top: 24px;
      right: 24px;
      bottom: 24px;
      left: 24px;
    }
  }

  &__heading {
    @extend .text-lg;
  }

  &__content {
    @extend .text-md;
    font-size: 8px;

    @include portraitTablet {
      font-size: 15px;
    }

    @include landscapeTablet {
      font-size: 22px;
    }
  }
}
