$font-size-root:           1em;

$mobileWidth:              512px;
$portraitTabletWidth:      768px;
$landscapeTabletWidth:     980px;
$desktopWidth:             1400px;
$wideDesktopWidth:         1880px;

$secondaryFont:           futura-pt-bold, Helvetica Neue, Helvetica, Arial, sans-serif;
$primaryFont:             futura-pt, Helvetica Neue, Helvetica, Arial, sans-serif;

$linkColor:                #E94E1B;
$linkColor-hover:          #E94E1B;

$fontColor:                #000000;
$addlfontColor:            #A6A6A6;

$addlColor-1:              #E94E1B;
$addlColor-2:              #00693B;
$addlColor-3:              #0F1C45;

$body-bg:                  #ffffff;
$section-bg:               #F2F2F2;

$border:                   #000000;

$has-error:                #CD493C;
