.map-wrapper {
  position: relative;
  margin-left: 3.3333%;
  height: 400px;

  svg {
    width: 130%;
    height: 100%;
  }
  @include portraitTablet {
    height: 720px;

    svg {
      width: 130%;
      height: 100%;
    }
  }
  @include landscapeTablet {
    height: 700px;
    margin-bottom: -72px;

    svg {
      width: 130%;
      height: 100%;
    }
  }
  @include desktop {
    height: 1160px;
    margin-top: 88px;

    svg {
      width: 130%;
      height: 100%;
    }
  }
  @include wideDesktop {
    height: auto;
  }
}

.map {
  shape-rendering: optimizeSpeed;
}

.map-subject {
  fill: $addlColor-3;
  cursor: pointer;
  transition: 0.2s ease;

  &:hover {
    fill: $addlColor-2;
  }
}