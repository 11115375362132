.review-list {
  margin-top: 32px;
  padding-bottom: 32px;
  @include landscapeTablet {
    margin-top: 96px;
    padding-bottom: 96px;
  }
  @include desktop {
    margin-top: 128px;
    padding-bottom: 128px;
  }
}

.review-item {
  @extend .row;

  &:nth-child(1),
  &:nth-child(3),
  &:nth-child(5) {
    .review-item__content-bubble {
      .icon {
        left: -17px;
        @include landscapeTablet {
          left: -35px;
        }
      }
    }

    .review-item__content-image {
      left: 0;
      @include portraitTablet {
        left: -24px;
      }
      @include landscapeTablet {
        left: 0;
      }

      &:after {
        left: -50%;
        transform: translateX(-50%) translateY(-50%);
        @include landscapeTablet {
          left: 0;
          transform: translateX(-50%) translateY(-50%);
        }
      }
    }
  }

  &:nth-child(2),
  &:nth-child(4) {
    margin-top: 24px;
    @include portraitTablet {
      margin-top: 80px;
    }
    @include landscapeTablet {
      margin-top: 106px;
    }
    @include desktop {
      margin-top: 160px;
    }
    @include wideDesktop {
      margin-top: 256px;
    }

    .review-item__content-bubble {
      padding: 16px;
      background-color: $addlColor-2;
      color: $body-bg;
      @include portraitTablet {
        padding: 24px 80px;
      }
      @include landscapeTablet {
        padding: 40px 80px;
      }
      @include desktop {
        padding: 40px 80px;
      }
      @include wideDesktop {
        padding: 64px 152px;
      }

      .icon {
        right: 18px;
        bottom: 20px;
        fill: $addlColor-2;
        @include portraitTablet {
          right: -8px;
        }
        @include landscapeTablet {
          right: -35px;
          bottom: 70px;
          fill: $addlColor-2;
        }
      }
    }

    .review-item__content-image {
      right: 0;
      @include portraitTablet {
        right: -24px;
      }
      @include landscapeTablet {
        right: 0;
      }

      img {
        transform: translateX(0px);
        @include landscapeTablet {
          transform: translateX(32px);
        }
      }

      &:after {
        right: -150%;
        transform: translateX(0%) translateY(-50%);
        @include landscapeTablet {
          right: -100%;
          transform: translateX(0%) translateY(-50%);
        }
      }
    }
  }

  &:nth-child(3) {
    margin-top: 24px;
    @include portraitTablet {
      margin-top: 40px;
    }
    @include landscapeTablet {
      margin-top: 24px;
    }
    @include desktop {
      margin-top: 64px;
    }
    @include wideDesktop {
      margin-top: 96px;
    }
  }

  &:nth-child(4) {
    margin-top: 24px;
    @include portraitTablet {
      margin-top: 64px;
    }
    @include landscapeTablet {
      margin-top: 48px;
    }
    @include desktop {
      margin-top: 96px;
    }
    @include wideDesktop {
      margin-top: 192px;
    }
  }

  &:nth-child(5) {
    margin-top: 24px;
    @include portraitTablet {
      margin-top: 64px;
    }
    @include landscapeTablet {
      margin-top: 96px;
    }
    @include desktop {
      margin-top: 128px;
    }
    @include wideDesktop {
      margin-top: 256px;
    }
  }
}

.review-item {
  position: relative;
  @extend .text-sm;

  &__heading-user {
    margin-bottom: 0;
    @extend .text-lg;
    @include landscapeTablet {
      margin-bottom: 24px;
    }
  }

  &__content {
    position: static;
  }
}

.review-item__content-image {
  position: absolute;
  top: 0;
  width: 40px;
  @include portraitTablet {
    width: 80px;
  }
  @include landscapeTablet {
    width: 214px;
    bottom: 0;
    top: auto;
  }
  @include desktop {
    width: 312px;
  }
  @include wideDesktop {
    width: 424px;
  }

  img {
    position: relative;
    z-index: 2;
    width: 100%;
    object-fit: contain;
  }

  &:after {
    content: "";
    position: absolute;
    width: 80px;
    height: 80px;
    border-radius: 100%;
    background-color: $addlColor-1;
    top: 50%;
    @include portraitTablet {
      width: 180px;
      height: 180px;
    }
    @include landscapeTablet {
      width: 360px;
      height: 360px;
    }
    @include desktop {
      width: 496px;
      height: 496px;
    }
    @include wideDesktop {
      width: 672px;
      height: 672px;
    }
  }
}

.review-item__content-bubble {
  position: relative;
  background-color: $section-bg;
  color: $fontColor;
  @extend .text-sm;
  padding: 16px;
  border-radius: 40px;
  box-shadow: 0 4px 4px rgba(0, 0, 0, 0.25);
  @include portraitTablet {
    border-radius: 80px;
    padding: 40px 64px 40px 80px;
  }
  @include landscapeTablet {
    margin-top: 16px;
    border-radius: 88px;
    padding: 40px 64px 40px 80px;
  }
  @include desktop {
    margin-top: 40px;
    border-radius: 120px;
    padding: 40px 64px 40px 96px;
  }
  @include wideDesktop {
    border-radius: 160px;
    padding: 64px 64px 64px 128px;
  }

  p {
    margin: 0;
  }

  p + p {
    margin-top: 12px;
    @include landscapeTablet {
      margin-top: 18px;
    }
    @include desktop {
      margin-top: 26px;
    }
    @include desktop {
      margin-top: 36px;
    }
  }

  .icon {
    position: absolute;
    bottom: 20px;
    width: 30px;
    height: 45px;
    fill: $section-bg;
    @include portraitTablet {
      bottom: 40px;
    }
    @include landscapeTablet {
      width: 60px;
      height: 90px;
      bottom: 70px;
    }
  }
}