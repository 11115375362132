.parallax_wrapper {
  position: relative;
}

.parallax_item {
  position: absolute;
  z-index: 5;
}

.parallax_item.parallax_item--1 {
  width: 6.92vw;
  max-width: 133px;
  right: 0;
  top: -20%;
  @media (min-width: 980px) {
    left: 5%;
    top: 0;
    right: unset;
  }
}

.parallax_item.parallax_item--2 {
  width: 24.27vw;
  max-width: 466px;
  left: 0;
  top: 0;
  @media (min-width: 980px) {
    top: -70%;
    left: unset;
    right: 0;
  }
}

.parallax_item--3 {
  max-width: 423px;
  width: 22.03vw;
  top: 0;
  right: 0;
  transform: translateY(-30%);
  z-index: 0;
}

.parallax_item--4 {
  max-width: 273px;
  width: 14.21vw;
  top: 70%;
  right: 10%;
  z-index: -1;
  display: none;
  @media (min-width: 980px) {
    display: block;
  }
}

.parallax_item--5 {
  width: 6.92vw;
  max-width: 133px;
  right: 2%;
  top: 50%;
  @media (min-width: 980px) {
    top: 100%;
  }
}

.parallax_item--6 {
  max-width: 283px;
  width: 14.73vw;
  top: 240%;
  right: 0;
  z-index: 0;
  @media (min-width: 980px) {
    right: 10%;
    top: 70%;
  }
}

.parallax_item--7 {
  max-width: 522px;
  width: 27.18vw;
  z-index: 4;
  bottom: -2%;
  left: 0;
  @media (min-width: 980px) {
    bottom: -10%;
    right: 0;
    left: unset;
    bottom: -7%;
  }
}

.parallax_item--8 {
  right: 0;
  top: -30%;
  width: 12vw;
  max-width: 236px;
  @media (min-width: 980px) {
    top: 0;
  }
}

.parallax_item--9 {
  left: 0;
  top: 0;
  width: 5.15vw;
  max-width: 99px;
  display: none;
  @media (min-width: 980px) {
    display: block;
  }
}

.parallax_item--10 {
  left: 0;
  top: 0;
  max-width: 215px;
  width: 11.19vw;
  display: none;
  @media (min-width: 980px) {
    display: block;
  }
}

.parallax_item--11 {
  right: 10%;
  bottom: -28%;
  width: 5.15vw;
  max-width: 99px;
  display: none;
  @media (min-width: 980px) {
    display: block;
  }
}

.parallax_item--12 {
  right: 10%;
  top: 0;
  width: 5.15vw;
  max-width: 215px;
  width: 11.19vw;
  display: none;
  @media (min-width: 980px) {
    display: block;
  }
}

.parallax_item--13 {
  top: 25%;
  width: 5.15vw;
  max-width: 99px;
  left: -5%;
  @media (min-width: 980px) {
    left: 5%;
  }
}

.parallax_item--14 {
  max-width: 283px;
  width: 14.73vw;
  top: 10%;
  left: 0;
  z-index: 0;
  @media (min-width: 980px) {
    display: none;
  }
}

.parallax_item--15 {
  left: 0;
  top: 40%;
  max-width: 84px;
  width: 10vw;
  @media (min-width: 980px) {
    display: none;
  }
}

.parallax_item--16 {
  right: 0;
  top: 0;
  max-width: 100px;
  width: 13vw;
  @media (min-width: 980px) {
    display: none;
  }
}

.parallax_item--17 {
  left: 0;
  top: 0;
  max-width: 100px;
  width: 13vw;
  @media (min-width: 980px) {
    display: none;
  }
}

.parallax_item--18 {
  right: 0;
  top: 0;
  max-width: 84px;
  width: 10vw;
  @media (min-width: 980px) {
    display: none;
  }
}

.parallax_item--19 {
  width: 6.92vw;
  max-width: 133px;
  left: 0;
  top: 30%;
  @media (min-width: 980px) {
    display: none;
  }
}

.parallax_item--20 {
  width: 17.57vw;
  max-width: 135px;
  left: 0;
  bottom: -45%;
  @media (min-width: 980px) {
    display: none;
  }
}

.benefit-item {
  .benefit-item__content-txt {
    transition: 0.5s linear;
  }

  &:nth-child(2n+1) .benefit-item__content-txt {
    opacity: 0;
    transform: translateX(-50%);
  }

  &:nth-child(2n+2) .benefit-item__content-txt {
    opacity: 0;
    transform: translateX(50%);
  }
}

.review-item {
  .review-item__content-bubble {
    transition: 0.3s linear;
  }

  &:nth-child(2n+1) {
    .review-item__content-bubble {
      transform: translateX(-50%);
    }
  }

  &:nth-child(2n+2) {
    .review-item__content-bubble {
      transform: translateX(50%);
    }
  }
}

[data-inview] {
  &.feature-item__heading,
  &.static-content {
    transform: translateX(-50%);
    opacity: 0;
    transition: 0.3s linear;

    &.isInView {
      transform: unset;
      opacity: 1;
    }
  }

  .benefit-item__content-number {
    transition: 0.3s linear;
  }

  &.benefit-item__content {
    &.isInView {
      .benefit-item__content-number {
        opacity: 1;
      }

      .benefit-item__content-txt {
        transform: unset;
        opacity: 1;
      }
    }
  }

  .benefit-item__content-number {
    opacity: 0;
  }

  &.benefit-item__heading-img {
    img {
      transform: scale(1.4);
      transition: 1s ease;
    }

    &.isInView {
      img {
        transform: unset;
      }
    }
  }

  &.review-item__content-bubble {
    opacity: 0;

    &.isInView {
      opacity: 1;
      transform: unset;
    }
  }
}

img[data-inview]:not(.isInView) {
  transform: scale(1.4)!important;
}

img[data-inview] {
  transition: 1s ease;

  &.isInView {
    transform: unset;
  }
}