body {
  color: $body-bg;
  background-color: $addlColor-1;
  background-size: cover;
  background-image: url("../img/static/grid.svg");
  @include desktop {
    background-attachment: fixed;
  }
}

.page-content {
  overflow: hidden;
}

.page-section {
  &--hero {
    padding: 80px 0 0;
    @include portraitTablet {
      padding: 160px 0 0;
    }
    @include landscapeTablet {
      padding: 228px 0 0;
    }
    @include desktop {
      padding: 228px 0 40px;
    }
    @include wideDesktop {
      padding: 296px 0 40px;
    }
  }

  &--feature {
    position: relative;
    color: $body-bg;
    background-color: $addlColor-3;

    &:after {
      content: "";
      position: absolute;
      top: -80px;
      right: 0;
      left: 0;
      width: 0;
      height: 0;
      border-style: solid;
      border-width: 0 0 80px 100vw;
      border-color: transparent transparent $addlColor-3 transparent;
      @include portraitTablet {
        top: -160px;
        border-width: 0 0 160px 100vw;
      }
      @include landscapeTablet {
        top: -160px;
        border-width: 0 0 160px 100vw;
      }
      @include desktop {
        top: -240px;
        border-width: 0 0 240px 100vw;
      }
      @include wideDesktop {
        top: -352px;
        border-width: 0 0 352px 100vw;
      }
    }
  }

  &--benefit {
    position: relative;
    color: $body-bg;
    background-color: $addlColor-3;
    @include portraitTablet {}
    @include landscapeTablet {
      padding-top: 108px;
      padding-bottom: 160px;
    }
    @include desktop {
      padding-top: 0;
      padding-bottom: 128px;
    }
    @include wideDesktop {
      padding-bottom: 256px;
    }
  }

  &--map {
    position: relative;
    padding-top: 96px;
    overflow: hidden;

    &:after {
      content: "";
      position: absolute;
      top: 0;
      right: 0;
      left: 0;
      width: 0;
      height: 0;
      border-style: solid;
      border-width: 0 100vw 60px 0;
      border-color: transparent $addlColor-3 transparent transparent;
      z-index: 2;
    }

    &:before {
      content: "";
      position: absolute;
      right: 0;
      bottom: 0;
      left: 0;
      width: 0;
      height: 0;
      border-style: solid;
      border-width: 0 0 60px 100vw;
      border-color: transparent transparent $addlColor-3 transparent;
      z-index: 2;
    }
    @include portraitTablet {
      padding-top: 160px;

      &:after {
        border-width: 0 100vw 130px 0;
      }

      &:before {
        border-width: 0 0 130px 100vw;
      }
    }
    @include landscapeTablet {
      padding-top: 160px;

      &:after {
        border-width: 0 100vw 160px 0;
      }

      &:before {
        border-width: 0 0 160px 100vw;
      }
    }
    @include desktop {
      padding-top: 228px;
      padding-bottom: 96px;

      &:after {
        border-width: 0 100vw 256px 0;
      }

      &:before {
        border-width: 0 0 256px 100vw;
      }
    }
    @include wideDesktop {
      padding-top: 308px;
      padding-bottom: 0;

      &:after {
        border-width: 0 100vw 336px 0;
      }

      &:before {
        border-width: 0 0 352px 100vw;
      }
    }

    .section-heading {
      text-align: center;
    }
  }

  &--review {
    padding-top: 0;
    background-color: $addlColor-3;
    color: $body-bg;
    @include landscapeTablet {
      padding-top: 16px;
    }
    @include desktop {
      padding-top: 72px;
    }
  }

  &--about {
    position: relative;
    padding-top: 64px;
    color: $body-bg;

    &:after {
      content: "";
      position: absolute;
      top: 0;
      right: 0;
      left: 0;
      width: 0;
      height: 0;
      border-style: solid;
      border-width: 64px 100vw 0 0;
      border-color: $addlColor-3 transparent transparent transparent;
    }
    @include landscapeTablet {
      padding-top: 220px;

      &:after {
        border-width: 180px 100vw 0 0;
      }
    }
    @include desktop {
      padding-top: 296px;

      &:after {
        border-width: 256px 100vw 0 0;
      }
    }
    @include wideDesktop {
      padding-top: 440px;

      &:after {
        border-width: 340px 100vw 0 0;
      }
    }
  }
}